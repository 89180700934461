.landing-wrap {
    margin-top: -3rem;
    min-height: 80vh;
    display: flex;
}

.landing-wrap .container {
    height: auto;
}

.landing-wrap p {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 400;
    margin-block-end: 2rem;
    line-height: 1.8rem;
    letter-spacing: 1.5pt;
}

.landing-logo {
    position: absolute;
    top: 40%;
    left: 45%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    z-index: -1;
}

@media screen and (max-width: 992px) {
    .landing-wrap {
        margin-top: 0;
        min-height: 60vh;
    }
}