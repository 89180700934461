.contact .title {
    text-align: left !important;
}

.contact h5 {
    margin-block-end: 0;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1rem;
}

.contact a {
    text-transform: lowercase;
    color: #333 !important;
}

.address {
    color: #333 !important;
}

.form-title {
    color: #333 !important;
}

.faq-title {
    text-align: center !important;
}

.accordion {
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
}

.accordion a {
    color: rgb(225, 5, 20) !important;
}

.accordion-item,
.accordion-header,
.accordion-button {
    background: transparent !important;
}

.accordion-header {
    margin-top: .9rem !important;
    margin-bottom: .9rem !important;
}

.accordion-button:active,
.accordion-button:focus,
.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

.accordion-button:not(.collapsed) {
    color: rgb(220, 53, 69) !important;
}

.accordion-button .collapsed {
    margin-bottom: 0 !important;
}

.accordion-header button {
    font-weight: 600 !important;
    padding: 0;
}

.accordion-item {
    font-size: .9rem;
    text-align: left;
    font-weight: 300;
}

.accordion-body {
    padding: 0 !important;
    margin-bottom: 1rem;
}

@media screen and (max-width: 991px) {
    .form-title {
        padding-top: 3rem !important;
    }

    .faq-title {
        text-align: left !important;
    }
}