.home-wrap {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.image-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: absolute; 
}

.image {
  height: 100vh;
  object-fit: cover;
  width: 100%;
  position: absolute; 
  opacity: 0; 
  /* transition: opacity .05s ease, transform .05s ease;  */
  z-index: -1;
}

.image.active {
  opacity: 1; 
  transform: scale(1.05);
}

.landing-slide {
  height: 100vh;
  width: 100vw;
  opacity: 0; 
  /* transition: opacity .05s ease, transform .05s ease;  */
}

.landing-slide.active {
  opacity: 1;
}

.contact-button {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  padding: 10px 20px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  z-index: 5; 
  transition: all 0.3s ease;
  font-size: 1rem;
}

.contact-button:hover {
  top: 49.7%;
}

@keyframes bounce {
  0%, 100% {
      transform: translateX(-50%) translateY(0);
  }
  50% {
      transform: translateX(-50%) translateY(-10px);
  }
}