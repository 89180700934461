.visuallyHidden {
    position: absolute; 
    overflow: hidden; 
    clip: rect(0 0 0 0); 
    height: 1px; width: 1px; 
    margin: -1px; padding: 0; border: 0; 
}

header {
    text-transform: uppercase !important;
    position: relative;
    z-index: 2 !important;
}

/* Desktop nav */
#nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 !important;
}

#nav {
    display: flex;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

#nav li {
    display: inline-block;
    margin-left: 1.5rem;
}

.bar {
    left: 0px;
    border-radius: 5pt;
    display: block;
    position: relative;
    transform-origin: right;
    height: 3pt !important;
    width: 28pt;
    background-color: rgb(225, 5, 20);
    transition: all 0.3s ease;
}

.burger-box{
    width: 30pt;
    height: 28pt;
    padding-top: 5pt;
}

.burger-box:hover {
    cursor: pointer;
}

.bar1{
    top: 0pt;
}

.bar2{
    top: 8pt;
}

.bar3{
    top: 16pt;
}

.bar1, .bar3{
    transform-origin: right;
}

:root{
    --top-offset-after-animation: 1.29pt;
    --left-offset-after-animation: -4.5pt;
}

.active.bar1 {
	transform: rotate(-45deg);
	top: var(--top-offset-after-animation);
    left: var(--left-offset-after-animation);
}

.active.bar2 {
	opacity: 0%;
}

.active.bar3 {
	transform: rotate(45deg);
	top: calc(16pt - var(--top-offset-after-animation));
    left: var(--left-offset-after-animation);
}

/* MOBILE NAV STYLES */
#mobileNav {
    padding-top: .4rem;
    z-index: 2 !important;
    position: relative;
}

#mobile-navbar {
    margin-top: 5rem;
    z-index: 3 !important;
}

#mobile-navbar > ul {
    padding: 0;
    padding-inline: 0;
    list-style: none;
}

#mobile-navbar > ul > li {
    display: block;
    text-align: center;
    margin-bottom: 3rem;
}

#mobile-navbar > ul > li > a {   
    font-size: 1.5rem;
}

.mobile-nav-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 !important;
}

/* Media for changing back */
@media only screen and (min-width: 768px) {
    #mobileNav {
        display: none;
    }
}

/* media for smartphone */
@media screen and (max-width: 768px) {
    #mobileNav {
        display: block;
    }

    #desktopNav {
        display: none;
    }
}