.guestlist {
    min-height: 75vh;
}

.guestlist a {
    font-weight: 600;
}

.guestlist__content {
    max-width: 600px;
    margin: 0 auto;
}