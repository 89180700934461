.lilys__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.lilys__content p {
    text-transform: uppercase;
    margin-top: 1rem;
}

.lobby-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: .3rem;
    margin-bottom: 4rem;
}